// @flow
import React from 'react';
import type { ProjectReport, Update } from 'store/project/types';
import { formatDate } from 'helpers/formatters/';
import Menu from 'components/Menu';
import Text from 'components/Text';
import Chip from 'components/Chip';
import Avatar from 'components/Avatar';
import { Flex } from '/components/Containers';
import UpdateEditDialog from 'dialogs/ProjectDialogs/UpdateEditDialog';
import { getUpdateColor, getUpdateType } from '../utils';
import { success, error } from 'utils/alerts';
import { useAlert } from 'hooks/useAlert';
import { useUsers } from 'hooks/useUsers';

type Props = {
  update: Update,
  deleteUpdateItem: (update: Update, report: ProjectReport) => void,
  selectedReport: ProjectReport,
  editable: boolean,
  reportDate: string,
  idx: number,
};

export default function ProjectUpdate(props: Props) {
  const {
    update,
    deleteUpdateItem,
    editable,
    selectedReport,
    reportDate,
    idx,
  } = props;

  const { createAlert } = useAlert();
  const { users: usersState } = useUsers();
  const { map: users } = usersState.data;

  const user = users[update.userId];
  const name = user ? `${user.firstName} ${user.lastName}` : '——';
  const thumbnail = user ? user.thumbnail : undefined;

  const [showEditUpdateDialog, setShowEditUpdateDialog] = React.useState(false);

  async function removeUpdate() {
    try {
      await deleteUpdateItem(update, selectedReport);
      createAlert({
        message: success('update').delete,
        type: 'success',
      });
    } catch {
      createAlert({
        message: error('update').delete,
        type: 'error',
      });
    }
  }

  const editUpdate = () => setShowEditUpdateDialog(true);
  const menuItems = [
    { name: 'Edit', color: 'primary', action: editUpdate },
    { name: 'Delete', color: 'error', action: removeUpdate },
  ];

  return (
    <>
      <Flex align="center" gutter={0}>
        <Flex item xs={12} sm={5} padding="0 5 0 0">
          <Text wordbreak="break-word">{update.summary}</Text>
        </Flex>
        <Flex item sm={3}>
          <Chip
            appearance={getUpdateColor(update.type)}
            label={getUpdateType(update.type)}
          />
        </Flex>
        <Flex item sm={2}>
          <Text>{formatDate(update.updatedAt || reportDate)}</Text>
        </Flex>
        <Flex item sm={1}>
          <Avatar name={name} src={thumbnail} />
        </Flex>
        <Flex item sm={1} style={{ textAlign: 'center' }}>
          {editable && (
            <Menu items={menuItems} iconButtonProps={{ alt: 'menu' }} />
          )}
        </Flex>
      </Flex>

      <UpdateEditDialog
        open={showEditUpdateDialog}
        idx={idx}
        update={update}
        onConfirm={() => setShowEditUpdateDialog(false)}
        onClose={() => setShowEditUpdateDialog(false)}
      />
    </>
  );
}
