/*
  This page is a temporary design to display all financial
  values from 10,000 feet for a specific project until we
  can pinpoint the exact values we want to display for a project

  The design of this will change in the future. However, all of the data
  from useProjectFinancials should be up to date and should
  have everything you need.
*/

/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';
import { useProjectFinancials } from 'hooks/useProjectFinancials';
import { use10kReports } from 'hooks/use10kReports';
import Card from 'components/Card';
import Loader from 'components/Loader';
import { Flex, Block } from 'components/Containers';
import Button from 'components/Button';
import List from 'components/List';
import Text from 'components/Text';
import { type Project } from 'store/project/types';
import { toCurrencyString } from 'utils/format';

import { color } from 'styles/constants';

type Props = {
  project: Project,
};

type BudgetProps = {
  rows: any,
  totals: any,
};

const styledCardContent = styled(Card.Content)`
  padding: 0;
`;

export default function ProjectFinanceCard(props: Props) {
  const { project } = props;
  const { tenKId, billingMethod } = project;
  const { data, loading } = useProjectFinancials(tenKId);
  const report = use10kReports(tenKId);

  const initialToggle = billingMethod !== 'Team for Time';
  const [toggleBilling, setToggleBilling] = React.useState(initialToggle);

  if (loading || report.loading) {
    return (
      <Card.Content>
        <Flex fullHeight padding="5 0" align="center" justify="center">
          <Loader size={75} />
        </Flex>
      </Card.Content>
    );
  }

  if (!data || !report.data) {
    return (
      <Card.Content>
        <Flex fullHeight padding="5 0" align="center" justify="center">
          <Text align="center" color="typography-medium-gray">
            There was an error processing the financial data.
          </Text>
        </Flex>
      </Card.Content>
    );
  }

  const PaymentToggle = () => {
    return (
      <Flex gutter={1} padding="0 0 4 0" align="center">
        <Flex justify="flex-end" margin="0">
          <Block width="160px">
            <Button
              fullWidth
              appearance={toggleBilling ? 'secondary' : 'primary'}
              onClick={() => {
                setToggleBilling(false);
              }}
            >
              Team for Time
            </Button>
          </Block>
        </Flex>
        <Flex margin="0">
          <Block width="160px">
            <Button
              fullWidth
              appearance={toggleBilling ? 'primary' : 'secondary'}
              onClick={() => {
                setToggleBilling(true);
              }}
            >
              Time & Materials
            </Button>
          </Block>
        </Flex>
      </Flex>
    );
  };

  const BudgetHeader = () => {
    return (
      <Flex align="center" height="40px">
        <Flex item xs={2} padding="0 1">
          <Text weight={600} color="typography-medium-gray" overflow="ellipsis">
            Name
          </Text>
        </Flex>

        <Flex item xs={2} justify="flex-end" padding="0 1">
          <Text weight={600} color="typography-medium-gray" overflow="ellipsis">
            Budget
          </Text>
        </Flex>

        <Flex item xs={2} justify="flex-end" padding="0 1">
          <Text weight={600} color="typography-medium-gray" overflow="ellipsis">
            {toggleBilling ? 'Incurred Amount' : 'Past Scheduled'}
          </Text>
        </Flex>

        <Flex item xs={2} justify="flex-end" padding="0 1">
          <Text weight={600} color="typography-medium-gray" overflow="ellipsis">
            Future Scheduled
          </Text>
        </Flex>

        <Flex item xs={2} justify="flex-end" padding="0 1">
          <Text weight={600} color="typography-medium-gray" overflow="ellipsis">
            Total
          </Text>
        </Flex>

        <Flex item xs={2} justify="flex-end" padding="0 1">
          <Text weight={600} color="typography-medium-gray" overflow="ellipsis">
            Gap
          </Text>
        </Flex>
      </Flex>
    );
  };

  const BudgetContent = (budgetProps: BudgetProps) => {
    const { financials } = budgetProps;
    const phases = financials;
    const totals = phases.pop();
    return (
      <styledCardContent>
        <Block
          height="24px"
          style={{ borderTop: `1px solid ${color.BORDER_COLOR}` }}
        />
        <List
          items={phases.map((row, idx) => (
            <Flex
              align="center"
              justify="center"
              padding="0 3"
              height="40px"
              key={idx}
            >
              <Flex item xs={2} padding="0 1">
                <Text size="normal" overflow="ellipsis">
                  {row.phase_name || row.name}
                </Text>
              </Flex>
              <Flex item xs={2} justify="flex-end" padding="0 1">
                <Text size="normal" overflow="ellipsis">
                  {toCurrencyString(row.budget)}
                </Text>
              </Flex>
              <Flex item xs={2} justify="flex-end" padding="0 1">
                <Text size="normal" overflow="ellipsis">
                  {toCurrencyString(row.past_or_incurred_dollars)}
                </Text>
              </Flex>
              <Flex item xs={2} justify="flex-end" padding="0 1">
                <Text size="normal" overflow="ellipsis">
                  {toCurrencyString(row.future_dollars)}
                </Text>
              </Flex>
              <Flex item xs={2} justify="flex-end" padding="0 1">
                <Text size="normal" overflow="ellipsis">
                  {toCurrencyString(row.total_dollars)}
                </Text>
              </Flex>
              <Flex item xs={2} justify="flex-end" padding="0 1">
                <Text
                  size="normal"
                  weight={600}
                  overflow="ellipsis"
                  color={row.gap < 0 ? color.RED : color.BLACK}
                >
                  {toCurrencyString(row.gap).replace('-', '')}
                </Text>
              </Flex>
            </Flex>
          ))}
        />
        <Block
          height="24px"
          style={{ borderBottom: `1px solid ${color.BORDER_COLOR}` }}
        />
        <List
          items={[totals].map((i, idx) => (
            <Flex
              align="center"
              justify="center"
              padding="0 3"
              height="88px"
              style={{ backgroundColor: '#F6FAFE' }}
              key={idx}
            >
              <Flex item xs={2} padding="0 1">
                <Text size="normal" overflow="ellipsis">
                  {i.phase_name || i.name}
                </Text>
              </Flex>
              <Flex item xs={2} justify="flex-end" padding="0 1">
                <Text size="normal" overflow="ellipsis">
                  {toCurrencyString(i.budget)}
                </Text>
              </Flex>
              <Flex item xs={2} justify="flex-end" padding="0 1">
                <Text size="normal" overflow="ellipsis">
                  {toCurrencyString(i.past_or_incurred_dollars)}
                </Text>
              </Flex>
              <Flex item xs={2} justify="flex-end" padding="0 1">
                <Text size="normal" overflow="ellipsis">
                  {toCurrencyString(i.future_dollars)}
                </Text>
              </Flex>
              <Flex item xs={2} justify="flex-end" padding="0 1">
                <Text size="normal" overflow="ellipsis">
                  {toCurrencyString(i.total_dollars)}
                </Text>
              </Flex>
              <Flex item xs={2} justify="flex-end" padding="0 1">
                <Text
                  size="normal"
                  weight={600}
                  overflow="ellipsis"
                  color={i.gap < 0 ? color.RED : color.BLACK}
                >
                  {toCurrencyString(i.gap)}
                </Text>
              </Flex>
            </Flex>
          ))}
        />
      </styledCardContent>
    );
  };

  const getInccuredAmount = phase_name => {
    const report_phases = report.data[0].rows;
    let incurred_amount = 0;

    report_phases.forEach(report_phase => {
      if (
        phase_name === report_phase.phase_name ||
        (phase_name === null &&
          report_phase.phase_name === '[Non Phase Specific]')
      ) {
        incurred_amount = report_phase.incurred_amount;
      }
    });

    return incurred_amount;
  };

  const performCalculations = rows => {
    return rows.map(row => {
      const { scheduled_dollars, future_dollars, name, phase_name } = row;

      const past_or_incurred_dollars = toggleBilling
        ? getInccuredAmount(phase_name)
        : scheduled_dollars - future_dollars;

      const total_dollars = past_or_incurred_dollars + future_dollars;

      const budget = row.budget_items.data.reduce((acc, cur) => {
        return acc + cur.amount;
      }, 0);

      const gap = budget - total_dollars;

      return {
        name: phase_name || name,
        budget,
        past_or_incurred_dollars,
        future_dollars,
        total_dollars,
        gap,
      };
    });
  };

  const getTotals = rows => {
    return rows.reduce(
      (acc, cur) => {
        return {
          ...acc,
          budget: acc.budget + cur.budget,
          past_or_incurred_dollars:
            acc.past_or_incurred_dollars + cur.past_or_incurred_dollars,
          future_dollars: acc.future_dollars + cur.future_dollars,
          total_dollars: acc.total_dollars + cur.total_dollars,
          gap: acc.gap + cur.gap,
        };
      },
      {
        name: 'Total',
        budget: 0,
        past_or_incurred_dollars: 0,
        future_dollars: 0,
        total_dollars: 0,
        gap: 0,
      }
    );
  };

  const gatherFinancials = financials => {
    const rows = financials.reduce((acc, cur) => {
      return [...acc, { ...cur }, ...cur.children.data];
    }, []);

    const calculatedRows = performCalculations(rows);

    const totals = getTotals(calculatedRows);

    return [...calculatedRows, totals];
  };

  const financials = gatherFinancials(data);

  return (
    <Block margin="5 0">
      <Flex justify="center">
        <PaymentToggle />
      </Flex>
      <Card>
        <Card.Header title={BudgetHeader()} fullWidth />
        <BudgetContent financials={financials} />
      </Card>
    </Block>
  );
}
