// @flow
import React from 'react';

import Button from 'components/Button';
import { Flex, Block } from 'components/Containers';

import { getUTCDate } from 'helpers/formatters/';
import Text from 'components/Text';
import { format, startOfWeek, lastDayOfWeek, isSameMonth } from 'date-fns';
import MilestoneAddDialog from 'dialogs/ProjectDialogs/MilestoneAddDialog';
import { Project, ProjectReport } from 'store/project/types';

type Props = {
  weekNo: number,
  editable: boolean,
  project: Project,
  selectedReport: ProjectReport,
};

export default function TimelineHeader(props: Props) {
  const [showAddMilestoneDialog, setShowAddMilestoneDialog] = React.useState(
    false
  );

  const { weekNo, editable, project, selectedReport } = props;

  const projectEnd = getUTCDate(project.end);
  const renderStartEnd = () => {
    if (!selectedReport) return '';

    const reportDateUtc = getUTCDate(selectedReport.reportDate);
    const start = startOfWeek(reportDateUtc, { weekStartsOn: 1 });
    const end = lastDayOfWeek(reportDateUtc, { weekStartsOn: 1 });

    let adjEnd;
    if (projectEnd < end) {
      adjEnd = getUTCDate(project.end);
    } else {
      adjEnd = lastDayOfWeek(reportDateUtc, { weekStartsOn: 1 });
    }
    const formattedStart = format(start, 'MMM DD');
    const formattedEnd = isSameMonth(start, adjEnd)
      ? format(adjEnd, 'DD')
      : format(adjEnd, 'MMM DD');

    return <span>{` ${formattedStart} - ${formattedEnd} `}</span>;
  };

  return (
    <>
      <Flex align="center">
        <Block flex="none" margin="0 4 0 0">
          <Text tag="h3" weight={600}>
            Week {weekNo || '-'} / {renderStartEnd()}
          </Text>
        </Block>
        {editable && (
          <Block flex="none">
            <Button
              data-testid="add-milestone-button"
              onClick={() => setShowAddMilestoneDialog(true)}
            >
              Add Milestone
            </Button>
          </Block>
        )}
      </Flex>
      <MilestoneAddDialog
        open={showAddMilestoneDialog}
        onConfirm={() => setShowAddMilestoneDialog(false)}
        onClose={() => setShowAddMilestoneDialog(false)}
      />
    </>
  );
}
