// @flow
import React from 'react';
import { formatDate, getUTCDate } from 'helpers/formatters/';
import Button from 'components/Button';
import { IconButton } from 'components/Icon';
import { Flex, Block } from 'components/Containers';
import Text from 'components/Text';
import type { Project } from 'store/project/types';
type Props = {
  project: Project,
  sideScroll: string => void,
  selectProjectReport: (index: number) => void,
};

export default function TimelineControls(props: Props) {
  const { project, selectProjectReport, sideScroll } = props;

  return (
    <Flex direction="column" align="flex-end">
      <Block flex="none">
        <Text tag="h4" weight={400}>
          {formatDate(getUTCDate(project.start), 'MMMM DD, YYYY')} -{' '}
          {formatDate(getUTCDate(project.end), 'MMMM DD, YYYY')}
        </Text>
      </Block>
      <Flex flex="none" gutter={1} width="auto" align="center">
        <Button size="small" onClick={() => selectProjectReport(-1)}>
          Current Week
        </Button>
        <Flex
          width="24px"
          height="24px"
          flex="none"
          bg="faux-white"
          margin="2px 0 0 0"
          circle
        >
          <IconButton
            noPadding
            alt="timeline scroll left"
            color="darkgray"
            name="arrowLeft"
            onClick={() => sideScroll('left')}
          />
        </Flex>
        <Flex
          width="24px"
          height="24px"
          flex="none"
          bg="faux-white"
          margin="2px 0 0 0"
          circle
        >
          <IconButton
            noPadding
            alt="timeline scroll right"
            color="darkgray"
            name="arrowRight"
            onClick={() => sideScroll('right')}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
