import React from 'react';
import { Formik } from 'formik';
import omit from 'lodash.omit';

import Button from 'components/Button';
import Card from 'components/Card';
import { Screen, Flex } from 'components/Containers';
import DocumentTitle from 'react-document-title';
import Header from 'components/Header';
import Text from 'components/Text';
import Toggle from 'components/Toggle';
import { useAlert } from 'hooks/useAlert';
import { generateReports, syncProjects } from 'services/project';
import type { GlobalSettings } from 'store/globalSettings/types';
import type { User } from 'store/user/types';
import { appearances } from 'styles/constants';
import { success, error } from 'utils/alerts';
import { isAdmin } from 'utils/auth';
import LoaderView from 'views/LoaderView';

type Props = {
  globalSettings: GlobalSettings,
  updateGlobalSettings: (payload: GlobalSettings) => void,
  isLoading: boolean,
  user: User,
};

export default function GlobalSettingsView(props: Props) {
  const { globalSettings, updateGlobalSettings, isLoading, user } = props;

  const { createAlert } = useAlert();

  const onSubmit = async values => {
    try {
      await updateGlobalSettings(values);
      createAlert({
        message: success('global settings').edit,
        type: 'success',
      });
    } catch {
      createAlert({
        message: error('global settings').edit,
        type: 'error',
      });
    }
  };

  const sync = async () => {
    try {
      await syncProjects();
      createAlert({
        message: success('project sync').complete,
        type: 'success',
      });
    } catch {
      createAlert({
        message: error('project sync').complete,
        type: 'error',
      });
    }
  };

  const generate = async () => {
    try {
      await generateReports();
      createAlert({
        message: success('report generation').complete,
        type: 'success',
      });
    } catch {
      createAlert({
        message: error('report generation').complete,
        type: 'error',
      });
    }
  };

  if (isLoading || !globalSettings) return <LoaderView />;

  const globalSettingsValues = omit(globalSettings, [
    'id',
    'createdAt',
    'updatedAt',
    'accountId',
  ]);

  return (
    <DocumentTitle title="Global Settings">
      <>
        <Header admin={isAdmin(user)}>
          <Flex item align="center">
            <Header.Title>Global Settings</Header.Title>
          </Flex>
        </Header>
        <Screen style={{ maxWidth: '700px' }}>
          <Flex item margin="3 0">
            <Card>
              <Card.Content flex>
                <Flex container direction="row" justify="space-between">
                  <Flex container direction="column" item xs={10}>
                    <Flex margin="0 0 1 0" item>
                      <Text tag="h3">Sync</Text>
                    </Flex>
                    <Flex item>
                      <Text color={appearances.INFORMATION}>
                        Updates project data in Engage with latest 10k feet data
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex align="center" item justify="flex-end" xs={2}>
                    <Button
                      appearance="primary"
                      disabled={false}
                      onClick={() => sync()}
                    >
                      Sync
                    </Button>
                  </Flex>
                </Flex>
              </Card.Content>
            </Card>
          </Flex>
          <Flex item margin="3 0">
            <Card>
              <Card.Content flex>
                <Flex container direction="row" justify="space-between">
                  <Flex container direction="column" item xs={8}>
                    <Flex margin="0 0 1 0" item>
                      <Text tag="h3">Generate Reports</Text>
                    </Flex>
                    <Flex item>
                      <Text color={appearances.INFORMATION}>
                        Generates this weeks reports for all active projects
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex align="center" item justify="flex-end" xs={4}>
                    <Button
                      appearance="primary"
                      disabled={false}
                      onClick={() => generate()}
                    >
                      Generate Reports
                    </Button>
                  </Flex>
                </Flex>
              </Card.Content>
            </Card>
          </Flex>
          <Flex item>
            <Card>
              <Card.Content flex>
                <Flex direction="column">
                  <Formik
                    initialValues={globalSettingsValues}
                    isInitialValid
                    onSubmit={values => onSubmit(values)}
                  >
                    {({ values, submitForm, isValid, setFieldValue }) => (
                      <>
                        <Flex align="center" direction="row" margin="0 0 3 0">
                          <Flex direction="column">
                            <Flex margin="0 0 1 0" item xs="10">
                              <Text tag="h3">Edit Past Reports</Text>
                            </Flex>
                            <Flex item>
                              <Text color={appearances.INFORMATION}>
                                Allows edits to project reports from the past
                              </Text>
                            </Flex>
                          </Flex>
                          <Flex item justify="flex-end" xs="2">
                            <Toggle
                              checked={
                                values.allowUsersToEditPastReportsForHolidaysOrIfTheyForgot
                              }
                              onChange={checked =>
                                setFieldValue(
                                  'allowUsersToEditPastReportsForHolidaysOrIfTheyForgot',
                                  checked
                                )
                              }
                            />
                          </Flex>
                        </Flex>
                        <Flex justify="flex-end">
                          <Button
                            appearance="primary"
                            onClick={submitForm}
                            disabled={!isValid || isLoading}
                          >
                            Save
                          </Button>
                        </Flex>
                      </>
                    )}
                  </Formik>
                </Flex>
              </Card.Content>
            </Card>
          </Flex>
        </Screen>
      </>
    </DocumentTitle>
  );
}
