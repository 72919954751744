// @flow
import { color, fontSize, spacing, appearanceToColor } from 'styles/constants';
import styled, { css } from 'styled-components';
import { lighten } from 'polished';

const intensity = 0.1;

const raised = css`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  }
`;

const appearanceStyles = props => {
  switch (props.appearance) {
    case 'primary':
      return css`
        border-radius: 4px;
        background: ${appearanceToColor(props.color)};
        color: ${color.WHITE};

        ${raised};

        & svg path {
          fill: ${color.WHITE};
        }

        &:hover {
          background: ${lighten(intensity, appearanceToColor(props.color))};
        }

        &[disabled] {
          background: ${color.DISABLED};
          color: ${color.WHITE};
          cursor: not-allowed;

          svg path {
            fill: ${color.WHITE};
          }
        }
      `;
    case 'secondary':
      return css`
        border-radius: 4px;
        background: ${color.WHITE};
        color: ${color.BLACK};

        & svg path {
          fill: ${appearanceToColor(props.color)};
        }

        &:hover {
          background: ${lighten(intensity, color.WHITE)};
          color: ${lighten(intensity, color.BLACK)};

          & svg path {
            fill: ${lighten(intensity, appearanceToColor(props.color))};
          }
        }

        &[disabled] {
          color: ${color.DISABLED};
          border-color: ${color.DISABLED};
          cursor: not-allowed;

          svg path {
            fill: ${color.DISABLED};
          }
        }
      `;
    case 'text':
      return css`
        border-radius: 4px;
        background: transparent;
        color: ${props.selected ? color.BLUE : appearanceToColor(props.color)};

        & svg path {
          fill: ${props.selected ? color.BLUE : appearanceToColor(props.color)};
        }

        &:hover {
          background: transparent;
          color: ${lighten(
            intensity,
            props.selected ? color.BLUE : appearanceToColor(props.color)
          )};

          & svg path {
            fill: ${lighten(
              intensity,
              props.selected ? color.BLUE : appearanceToColor(props.color)
            )};
          }
        }

        &[disabled] {
          color: ${color.DISABLED};
          cursor: not-allowed;

          svg path {
            fill: ${color.DISABLED};
          }
        }
      `;
    default:
      return null;
  }
};

const sizeStyles = props => {
  switch (props.size) {
    case 'small':
      return css`
        height: 28px;
        padding: ${spacing(0, 'small')};
        font-size: ${fontSize.small};
      `;
    case 'medium':
      return css`
        height: 32px;
        padding: ${spacing(0, 'normal')};
        font-size: ${fontSize.normal};
      `;
    case 'large':
      return css`
        height: 37px;
        padding: ${spacing(0, 'large')};
        font-size: ${fontSize.normal};
      `;
    default:
      return null;
  }
};

const fullWidth = props =>
  props.fullWidth &&
  css`
    width: 100%;
  `;

export default styled.button`
  border: none;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  line-height: 1.25;
  text-align: center;
  text-transform: none;
  box-shadow: none;
  width: auto;
  max-width: 100%;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
  outline: none;
  cursor: pointer;

  ${appearanceStyles};
  ${sizeStyles};
  ${fullWidth};
`;
