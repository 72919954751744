import React from 'react';

import Card from 'components/Card';
import Text from 'components/Text';
import Loader from 'components/Loader';
import { Flex } from 'components/Containers';
import { color } from 'styles/constants';
import { useProjectFinancials } from 'hooks/useProjectFinancials';
import { use10kReports } from 'hooks/use10kReports';
import { toCurrencyString } from 'utils/format';

type Props = {
  project: Project,
};

const ProjectBudget = (props: Props) => {
  const { project } = props;
  const { tenKId, billingMethod } = project;
  const { data, loading } = useProjectFinancials(tenKId);
  const report = use10kReports(tenKId);

  if (loading || report.loading) {
    return (
      <Card>
        <Card.Header
          title={
            <Flex height="45px" align="center">
              <Text tag="h3" weight={600}>
                Budget
              </Text>
            </Flex>
          }
        />
        <Card.Content>
          <Flex fullHeight padding="5 0" align="center" justify="center">
            <Loader size={70} />
          </Flex>
        </Card.Content>
      </Card>
    );
  }

  if (!data || !report.data) {
    return (
      <Card>
        <Card.Header
          title={
            <Flex height="45px" align="center">
              <Text tag="h3" weight={600}>
                Budget
              </Text>
            </Flex>
          }
        />
        <Card.Content>
          <Flex fullHeight padding="5 0" align="center" justify="center">
            <Text align="center">
              There was an error processing the financial data.
            </Text>
          </Flex>
        </Card.Content>
      </Card>
    );
  }

  const getInccuredAmount = phaseName => {
    const reportPhases = report.data[0].rows;
    let incurredAmount = 0;

    reportPhases.forEach(reportPhase => {
      if (
        phaseName === reportPhase.phase_name ||
        (phaseName === null &&
          reportPhase.phase_name === '[Non Phase Specific]')
      ) {
        incurredAmount = reportPhase.incurred_amount;
      }
    });

    return incurredAmount;
  };

  const getProjectBudget = projectData => {
    const rows = projectData.reduce((acc, cur) => {
      return [...acc, { ...cur }, ...cur.children.data];
    }, []);

    return rows.reduce((acc, cur) => {
      return (
        acc +
        cur.budget_items.data.reduce((accInner, curInner) => {
          return accInner + curInner.amount;
        }, 0)
      );
    }, 0);
  };

  const getProjectGap = (projectData, projectBudget) => {
    // TODO: Update the gap calcuation and presentation to account for milestone
    // based projects once support for milestone based billing has been decided
    // on and implemented in Engage.

    const rows = projectData.reduce((acc, cur) => {
      return [...acc, { ...cur }, ...cur.children.data];
    }, []);

    if (billingMethod === 'Team for Time') {
      return (
        projectBudget -
        rows.reduce((acc, cur) => {
          return acc + cur.scheduled_dollars;
        }, 0)
      );
    }

    return (
      projectBudget -
      rows.reduce((acc, cur) => {
        const { phase_name: phaseName, future_dollars: futureDollars } = cur;
        return acc + getInccuredAmount(phaseName) + futureDollars;
      }, 0)
    );
  };

  const budget = getProjectBudget(data);
  const gap = getProjectGap(data, budget);

  return (
    <>
      <Card>
        <Card.Header
          title={
            <Flex height="45px" align="center">
              <Text tag="h3" weight={600}>
                Budget
              </Text>
            </Flex>
          }
        />
        <Card.Content>
          <Flex direction="column">
            <Flex padding="1 0">
              <Text
                size="xsmall"
                color={color.TYPOGRAPHY_MEDIUM_GRAY}
                weight={600}
              >
                TOTAL
              </Text>
            </Flex>
            <Flex padding="0 0 3">
              <Text size="large">{toCurrencyString(budget)}</Text>
            </Flex>
            <Flex padding="0 0 1">
              <Text
                size="xsmall"
                color={color.TYPOGRAPHY_MEDIUM_GRAY}
                weight={600}
              >
                GAP
              </Text>
            </Flex>
            <Flex padding="0 0 1">
              <Text size="large" color={gap < 0 ? color.RED : color.BLACK}>
                {toCurrencyString(gap).replace('-', '')}
              </Text>
            </Flex>
          </Flex>
        </Card.Content>
      </Card>
    </>
  );
};

export default ProjectBudget;
