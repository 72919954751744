// @flow
import React, { createRef, useEffect } from 'react';
import Card from 'components/Card';
import Text from 'components/Text';
import { Block, Flex } from 'components/Containers';
import type { Project, ProjectReport, Milestone } from 'store/project/types';
import TimelineControls from './components/TimelineControls';
import TimelineWeek from './components/TimelineWeek';
import TimelineHeader from './components/TimelineHeader';
import { color } from 'styles/constants';

type Props = {
  project: Project,
  reports: Array<ProjectReport>,
  milestones: Array<Milestone>,
  selectedReport: ProjectReport,
  totalWeeks: number,
  weekNo: number,
  selectProjectReport: (index: number) => void,
};

const ownerDocument = node => (node && node.ownerDocument) || document;

const emptyFutureReport = (weekNo: number) => ({
  weekNo,
  isSelected: false,
  content: {},
});

export default function ProjectTimeline(props: Props) {
  const {
    project,
    reports,
    milestones,
    selectedReport,
    totalWeeks,
    weekNo,
    selectProjectReport,
  } = props;

  const scrollTable = createRef(null);
  const focusTimeout = createRef(null);
  const reportRef = createRef(null);
  const currentWeek = createRef(null);
  const currentWeekNo = reports.length ? reports[reports.length - 1].weekNo : 1;

  function handleTableFocus() {
    const node = ownerDocument(scrollTable);

    if (selectedReport) {
      const el = node.getElementById(`week-${selectedReport.weekNo}`);

      if (el && scrollTable.current) {
        scrollTable.current.scrollLeft =
          el.offsetLeft - 35 - scrollTable.current.offsetWidth / 2;
      }
    }
  }

  function setInitialFocus() {
    if (reportRef.current) {
      reportRef.current.focus();
    } else if (scrollTable.current) {
      scrollTable.current.focus();
    }
  }

  function setCurrentWeek() {
    if (reports.length) {
      const node = ownerDocument(scrollTable);
      currentWeek.current = node.getElementById(`week-${currentWeekNo}`);
    }
  }

  function onKeyDown(event) {
    const { key, metaKey, ctrlKey } = event;
    const table = scrollTable.current;
    const node = ownerDocument(scrollTable);
    const inFocus = node.activeElement;
    const week = inFocus.id && inFocus.id.includes('week');

    // Command/Control + j or k is used to move through project carousel.
    // Command + ArrowLeft or ArrowRight is a browser-level shortcut.
    if (metaKey || ctrlKey) return;

    if (key === 'ArrowLeft' || (key === 'j' && table === inFocus)) {
      node.getElementById(`week-${currentWeekNo}`).focus();
    }

    if (key === 'ArrowRight' || (key === 'k' && table === inFocus)) {
      table.firstChild.focus();
    }

    if (key === 'ArrowLeft' || (key === 'j' && week)) {
      event.preventDefault();

      if (inFocus.previousElementSibling) {
        inFocus.previousElementSibling.focus();
      }
    } else if (key === 'ArrowRight' || (key === 'k' && week)) {
      event.preventDefault();

      if (inFocus.nextElementSibling && inFocus !== currentWeek.current) {
        inFocus.nextElementSibling.focus();
      }
    }
  }

  function onReportKeyDown(report) {
    if (event.key === 'Enter') {
      const el = ownerDocument(scrollTable);
      el.getElementById(`week-${report.weekNo}`).focus();
      return report.statuses && selectProjectReport(report.weekNo - 1);
    }
    return null;
  }

  function onReportClick(report) {
    return report.statuses && selectProjectReport(report.weekNo - 1);
  }

  useEffect(() => {
    focusTimeout.current = setTimeout(() => {
      setInitialFocus();
      setCurrentWeek();
    });
    handleTableFocus();
    return () => {
      if (focusTimeout.current) {
        clearTimeout(focusTimeout.current);
      }
    };
  }, [selectedReport]);

  const sideScroll = (direction: string) => {
    scrollTable.current.scrollLeft += direction === 'left' ? -15 : 15;
  };
  const getMilestoneForWeek = (week: number) => {
    return milestones.filter(m => m.weekNo === week);
  };

  const allReports = totalWeeks
    ? [...Array(totalWeeks)].map(
        (w, idx) => reports[idx] || emptyFutureReport(idx + 1)
      )
    : [];

  return (
    <Card>
      <Card.Header
        title={
          <TimelineHeader
            selectedReport={selectedReport}
            weekNo={weekNo}
            editable={!project.archive}
            project={project}
          />
        }
        rightHeader={
          <TimelineControls
            project={project}
            selectProjectReport={selectProjectReport}
            sideScroll={sideScroll}
          />
        }
      />
      <Card.Content>
        <Flex style={{ whiteSpace: 'nowrap' }}>
          <Block width="80px" margin="0 2 0 0" padding="1 0 0 0">
            <Block margin="0 0 4">
              <Text
                align="right"
                size="xsmall"
                color={color.TYPOGRAPHY_MEDIUM_GRAY}
                weight={600}
              >
                MILESTONE
              </Text>
            </Block>
            <Block margin="0 0 2">
              <Text
                align="right"
                size="xsmall"
                color={color.TYPOGRAPHY_MEDIUM_GRAY}
                weight={600}
              >
                WEEK
              </Text>
            </Block>
            <Block margin="0 0 1">
              <Text
                align="right"
                size="xsmall"
                color={color.TYPOGRAPHY_MEDIUM_GRAY}
                weight={600}
              >
                PROJECT
              </Text>
            </Block>
            <Block margin="0 0 1">
              <Text
                align="right"
                size="xsmall"
                color={color.TYPOGRAPHY_MEDIUM_GRAY}
                weight={600}
              >
                CLIENT
              </Text>
            </Block>
            <Block margin="0 0 1">
              <Text
                align="right"
                size="xsmall"
                color={color.TYPOGRAPHY_MEDIUM_GRAY}
                weight={600}
              >
                GROWTH
              </Text>
            </Block>
          </Block>
          <Flex
            align="baseline"
            style={{ overflowX: 'auto', outline: 'none' }}
            ref={scrollTable}
            padding="0 0 1 0"
            onKeyDown={onKeyDown}
            tabIndex={-1}
          >
            {allReports.map((report, idx) => (
              <TimelineWeek
                key={idx}
                report={report}
                reportRef={reportRef}
                tabIndex={report.isSelected ? 0 : -1}
                milestones={getMilestoneForWeek(report.weekNo)}
                editable={!project.archive}
                onReportClick={onReportClick}
                onReportKeyDown={onReportKeyDown}
                currentWeekNo={currentWeekNo}
              />
            ))}
          </Flex>
        </Flex>
      </Card.Content>
    </Card>
  );
}
